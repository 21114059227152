import React from "react";
import "./MainContent.css";

const MainContent = () => {
  return (
    <div className="main-content">
      <div className="centered-text">
        <h1>Welcome to FILF Meme Coin</h1>
        <p>Coming soon...</p>
      </div>
    </div>
  );
};

export default MainContent;
